<!-- Screenshots -->
<div class="slider-2">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">

        <!-- Image Slider -->
        <div class="slider-container">
          <div class="swiper-container image-slider">
            <div class="swiper-wrapper">

              <!-- Slide -->
              <div class="swiper-slide">
                <a href="assets/images/screenshot-3.png.png" class="popup-link" data-effect="fadeIn">
                  <img class="img-fluid" src="assets/images/screenshot-3.png" alt="alternative">
                </a>
              </div>
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <a href="assets/images/screenshot-2.png" class="popup-link" data-effect="fadeIn">
                  <img class="img-fluid" src="assets/images/screenshot-2.png" alt="alternative">
                </a>
              </div>
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <a href="assets/images/screenshot-3.png" class="popup-link" data-effect="fadeIn">
                  <img class="img-fluid" src="assets/images/screenshot-3.png" alt="alternative">
                </a>
              </div>
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <a href="assets/images/screenshot-4.png" class="popup-link" data-effect="fadeIn">
                  <img class="img-fluid" src="assets/images/screenshot-4.png" alt="alternative">
                </a>
              </div>
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <a href="assets/images/screenshot-5.png" class="popup-link" data-effect="fadeIn">
                  <img class="img-fluid" src="assets/images/screenshot-5.png" alt="alternative">
                </a>
              </div>
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <a href="assets/images/screenshot-6.png" class="popup-link" data-effect="fadeIn">
                  <img class="img-fluid" src="assets/images/screenshot-6.png" alt="alternative">
                </a>
              </div>
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <a href="assets/images/screenshot-7.png" class="popup-link" data-effect="fadeIn">
                  <img class="img-fluid" src="assets/images/screenshot-7.png" alt="alternative">
                </a>
              </div>
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <a href="assets/images/screenshot-8.png" class="popup-link" data-effect="fadeIn">
                  <img class="img-fluid" src="assets/images/screenshot-8.png" alt="alternative">
                </a>
              </div>
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <a href="assets/images/screenshot-9.png" class="popup-link" data-effect="fadeIn">
                  <img class="img-fluid" src="assets/images/screenshot-9.png" alt="alternative">
                </a>
              </div>
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <a href="assets/images/screenshot-10.png" class="popup-link" data-effect="fadeIn">
                  <img class="img-fluid" src="assets/images/screenshot-10.png" alt="alternative">
                </a>
              </div>
              <!-- end of slide -->

            </div> <!-- end of swiper-wrapper -->

            <!-- Add Arrows -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
            <!-- end of add arrows -->

          </div> <!-- end of swiper-container -->
        </div> <!-- end of slider-container -->
        <!-- end of image slider -->

      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of slider-2 -->
<!-- end of screenshots -->
