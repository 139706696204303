<!-- Navigation -->
<nav class="navbar navbar-expand-md navbar-dark navbar-custom fixed-top">

  <!-- Image Logo -->
  <a class="navbar-brand logo-image" href="index.html"><img src="assets/images/logo.svg" alt="alternative"></a>

  <!-- Mobile Menu Toggle Button -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-awesome fas fa-bars"></span>
    <span class="navbar-toggler-awesome fas fa-times"></span>
  </button>
  <!-- end of mobile menu toggle button -->

  <div class="collapse navbar-collapse" id="navbarsExampleDefault">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link page-scroll" href="#header">HOME <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link page-scroll" href="#story">OUR STORY</a>
      </li>
      <li class="nav-item">
        <a class="nav-link page-scroll" href="#features">SERVICES</a>
      </li>
      <li class="nav-item">
        <a class="nav-link page-scroll" routerLink="/pricing">PRICING</a>
      </li>

      <!-- Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle page-scroll" href="#details" id="infoDropdown" role="button" aria-haspopup="true" aria-expanded="false">INFO</a>
        <div class="dropdown-menu" aria-labelledby="infoDropdown">
          <a class="nav-link page-scroll dropdown-item" href="#preview"><span class="item-text">HOW IT WORKS</span></a>
          <div class="dropdown-items-divide-hr"></div>
          <a class="nav-link page-scroll dropdown-item" href="#benefits"><span class="item-text">BENEFITS</span></a>
          <div class="dropdown-items-divide-hr"></div>
          <a class="nav-link page-scroll dropdown-item" href="#industries"><span class="item-text">INDUSTRIES</span></a>
          <div class="dropdown-items-divide-hr"></div>
          <a class="nav-link page-scroll dropdown-item" href="#contact"><span class="item-text">CONTACT</span></a>
        </div>
      </li>
      <!-- end of dropdown menu -->

      <!-- Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle page-scroll" href="#details" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">DETAILS</a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/web-terms"><span class="item-text">TERMS CONDITIONS</span></a>
          <div class="dropdown-items-divide-hr"></div>
          <a class="dropdown-item" routerLink="/web-privacy"><span class="item-text">PRIVACY POLICY</span></a>
        </div>
      </li>
      <!-- end of dropdown menu -->

      <!-- <li class="nav-item">
        <a class="nav-link page-scroll" href="https://portal.just-queue.co.za/authentication/signin">SIGN IN</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link page-scroll" href="#contact">CONTACT ME</a>
      </li>
    </ul>
    <span class="nav-item social-icons">
                <span class="fa-stack">
                    <a href="https://www.instagram.com/just.queue/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
                        <i class="fas fa-circle fa-stack-2x"></i>
                        <i class="fab fa-instagram fa-stack-1x"></i>
                    </a>
                </span>
                <span class="fa-stack">
                    <a href="https://www.linkedin.com/in/just-queue-561902296/">
                        <i class="fas fa-circle fa-stack-2x"></i>
                        <i class="fab fa-linkedin-in fa-stack-1x"></i>
                    </a>
                </span>
            </span>
  </div>
</nav> <!-- end of navbar -->
<!-- end of navigation -->
