<!-- Services -->
<div id="features" class="services">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2>SERVICES</h2>
        <p class="p-heading p-large">Driving your business to efficiency could start at any of the following services:</p>
      </div> <!-- end of col -->
    </div> <!-- end of row -->
    <div class="row services-grid">
        <div class="element clearfix">
          <div class="image-wrapper">
            <a class="popup-with-move-anim" href="#service-1"><img src="assets/images/queue-management.png" alt="alternative"></a>
          </div> <!-- end of image-wrapper -->
          <h4>Queue Management</h4>
          <p class="text">
            The life of a business is its customers. Prioritizing customer comfort and convenience is what keeps them coming
          </p>
        </div> <!-- end of div -->
        <div class="element clearfix">
          <div class="image-wrapper">
            <a class="popup-with-move-anim" href="#service-2"><img src="assets/images/visualization.png" alt="alternative"></a>
          </div> <!-- end of image-wrapper -->
          <h4>Process Visualisation</h4>
          <p class="text">
            Have you evaluated the efficiency and effectiveness of your processes towards the bottom line?
          </p>
        </div> <!-- end of div -->
        <div class="element clearfix">
          <div class="image-wrapper">
            <a class="popup-with-move-anim" href="#service-3"><img src="assets/images/analytics.png" alt="alternative"></a>
          </div> <!-- end of image-wrapper -->
          <h4>Process Analytics</h4>
          <p class="text">Process Analytics is like having a backstage pass to your business performance.</p>
        </div> <!-- end of div -->
        <div class="element clearfix">
          <div class="image-wrapper">
            <a class="popup-with-move-anim" href="#service-4"><img src="assets/images/solutions.png" alt="alternative"></a>
          </div> <!-- end of image-wrapper -->
          <h4>Solution-based Process</h4>
          <p class="text">In the case where you want to improve your processes but do not know how to, we are there for you.</p>
        </div> <!-- end of div -->
        <div class="element clearfix">
          <div class="image-wrapper">
            <img src="assets/images/more.png" alt="alternative">
          </div> <!-- end of image-wrapper -->
          <h4>And More...</h4>
          <p class="text">We are not limited to what is mentioned here, but can go deeper into what you need to understand around the effectiveness of you processes to serve your consumer.</p>
        </div> <!-- end of div -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of basic-2 -->
<!-- end of services -->

<!-- Service Lightboxes -->
<!-- Lightbox -->
<div id="service-1" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/queue-management.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Queue Management</h3>
        <p>JustQueue offers your consumers the ability to manage their time, by allowing them to wait wherever and knowing how long it takes for them to be served or finish the service. Those that are frequent visitors can even plan their day around the least busy time your business operates.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->

<!-- Lightbox -->
<div id="service-2" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/visualization.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Process Visualisation</h3>
        <p>Looking at the health of your processes and the rate at which it moves against how satisfied the customers are. Looking at the time it takes for each client or customer to be serviced and how satisfied each customer is. Our drive is to visualize what you would need to see to make more informed decisions.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->

<!-- Lightbox -->
<div id="service-3" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/analytics.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Process Analytics</h3>
        <p>Once we have visualized the processes for you, the information is then translated into a data presentation that allows you to see how and where you can improve your business. This is sure to cure the “guessing game” syndrome and allow you to make realistic and informed decisions that are customer-service driven and simultaneously profitable.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->

<!-- Lightbox -->
<div id="service-4" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/solutions.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Solution-based Process</h3>
        <p>Our team of process engineers are equipped to use the data we have gathered about your processes and suggest measures that will drive better process flow and lead to efficient and effective service delivery.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->

<!-- end of lightbox -->