<!-- Expertise -->
<div class="cards-2" id="benefits">
  <div class="container">
    <div class="row">
      <div class="col-lg-12"><!-- end of box-heading-wrapper -->
        <h2 class="h2-heading">Benefits</h2>
        <p class="p-heading">Here are some of the reasons to consider JustQueue:</p>
      </div> <!-- end of col -->
    </div> <!-- end of row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="benefits-grid">

          <!-- Card -->
          <div class="card">
            <div class="card-image">
              <i class="fas fa-lightbulb"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">Fast and Easy to implement</h4>
              <p>Experience swift and efficient deployment with our easily implementable service lineup</p>
            </div>
          </div>
          <!-- end of card -->

          <!-- Card -->
          <div class="card">
            <div class="card-image">
              <i class="fas fa-calculator"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">Assessing Queue Requirements</h4>
              <p>Our platform empowers you to evaluate and measure the demands placed on queues</p>
            </div>
          </div>
          <!-- end of card -->

          <!-- Card -->
          <div class="card">
            <div class="card-image">
              <i class="fas fa-desktop"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">Monitoring</h4>
              <p>Utilize our solution for effective tracking and analysis of your business performance</p>
            </div>
          </div>
          <!-- end of card -->

          <!-- Card -->
          <div class="card">
            <div class="card-image">
              <i class="fas fa-cog"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">Service Planning</h4>
              <p>Elevate your service planning capabilities with our solution, offering a robust implementation strategy for seamless operations</p>
            </div>
          </div>
          <!-- end of card -->

          <!-- Card -->
          <div class="card">
            <div class="card-image">
              <i class="fas fa-glasses"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">Dynamic Process & Queue Analytics</h4>
              <p>Explore dynamic analytics with our solution, ensuring you stay informed about your processes &  queue performance in real time</p>
            </div>
          </div>
          <!-- end of card -->

          <!-- Card -->
          <div class="card">
            <div class="card-image">
              <i class="fas fa-chart-bar"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">Reporting</h4>
              <p>Access comprehensive analytics insights through our solution with detailed reporting options available on a daily, weekly, monthly, or yearly basis</p>
            </div>
          </div>
          <!-- end of card -->

          <!-- Card -->
          <div class="card">
            <div class="card-image">
              <i class="fas fa-cloud"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">Cloud Hosted</h4>
              <p>Leverage the power of cloud infrastructure with our solution for optimal performance and accessibility</p>
            </div>
          </div>
          <!-- end of card -->

        </div>
      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of cards-1 -->
<!-- end of expertise -->
