import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  public formMessage: string = '';
  public formData = {
    name: '',
    email: '',
    message: ''
  };

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }
  sendEmail(formData: any) {
    this.formMessage = ''; // Reset the form message

    // Check if any field is empty or contains only whitespace
    if (!formData.name || formData.name.trim().length === '' || !formData.email || formData.email.trim().length === '' || !formData.message || formData.message.trim().length === '') {
      console.log('Please fill all fields!');
      this.formMessage = 'Please fill all fields!';
      return; // Stop the function execution
    }
  
    // If all fields are filled, proceed with sending the email
    this.http.post('http://localhost:3000/send', formData)
      .subscribe(
        (response: any) => {
          console.log('Success!', response);
          this.formMessage = response.message;
        },
        error => {
          console.error('Error!', error);
          this.formMessage = error.error.message;
        }
      );
  }
}