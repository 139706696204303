<!-- Copyright -->
<div class="copyright">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <p class="p-small">Copyright © 2020 <a href="">Just Queue</a> - All rights reserved</p>
      </div> <!-- end of col -->
    </div> <!-- enf of row -->
  </div> <!-- end of container -->
</div> <!-- end of copyright -->
<!-- end of copyright -->
