<!-- Testimonials -->
<div class="slider-1">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">

        <!-- Card Slider -->
        <div class="slider-container">
          <div class="swiper-container card-slider">
            <div class="swiper-wrapper">

              <!-- Slide -->
              <div class="swiper-slide">
                <div class="card">
                  <img class="card-image" src="assets/images/testimonial-1.jpg" alt="alternative">
                  <div class="card-body">
                    <p class="testimonial-text">I just finished my trial period and was so amazed with the support and results that I purchased Leno.</p>
                    <p class="testimonial-author">Jude Thorn - Designer</p>
                  </div>
                </div>
              </div> <!-- end of swiper-slide -->
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <div class="card">
                  <img class="card-image" src="assets/images/testimonial-2.jpg" alt="alternative">
                  <div class="card-body">
                    <p class="testimonial-text">I don't know how I managed to get work done without Leno. The speed of this application is amazing!</p>
                    <p class="testimonial-author">Roy Smith - Developer</p>
                  </div>
                </div>
              </div> <!-- end of swiper-slide -->
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <div class="card">
                  <img class="card-image" src="assets/images/testimonial-3.jpg" alt="alternative">
                  <div class="card-body">
                    <p class="testimonial-text">This app has the potential of becoming a mandatory tool in every marketer's day to day operations.</p>
                    <p class="testimonial-author">Marsha Singer - Marketer</p>
                  </div>
                </div>
              </div> <!-- end of swiper-slide -->
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <div class="card">
                  <img class="card-image" src="assets/images/testimonial-4.jpg" alt="alternative">
                  <div class="card-body">
                    <p class="testimonial-text">Searching for a great marketing automation app was difficult but thankfully I found Leno.</p>
                    <p class="testimonial-author">Tim Shaw - Designer</p>
                  </div>
                </div>
              </div> <!-- end of swiper-slide -->
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <div class="card">
                  <img class="card-image" src="assets/images/testimonial-5.jpg" alt="alternative">
                  <div class="card-body">
                    <p class="testimonial-text">Leno's support team is amazing. They've helped me with some issues and I am so grateful to them.</p>
                    <p class="testimonial-author">Lindsay Spice - Marketer</p>
                  </div>
                </div>
              </div> <!-- end of swiper-slide -->
              <!-- end of slide -->

              <!-- Slide -->
              <div class="swiper-slide">
                <div class="card">
                  <img class="card-image" src="assets/images/testimonial-6.jpg" alt="alternative">
                  <div class="card-body">
                    <p class="testimonial-text">Who would have thought that Leno can provide such amazing results in just a few weeks of use</p>
                    <p class="testimonial-author">Ann Black - Developer</p>
                  </div>
                </div>
              </div> <!-- end of swiper-slide -->
              <!-- end of slide -->

            </div> <!-- end of swiper-wrapper -->

            <!-- Add Arrows -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
            <!-- end of add arrows -->

          </div> <!-- end of swiper-container -->
        </div> <!-- end of slider-container -->
        <!-- end of card slider -->

      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of slider-1 -->
<!-- end of testimonials -->
