
<app-header></app-header>

<app-story></app-story>

<app-services></app-services>

<!--<app-features></app-features>-->

<app-video-preview></app-video-preview>

<!--<app-details></app-details>-->

<!--<app-screenshots></app-screenshots>-->

<app-expertise></app-expertise>

<app-products></app-products>

<!--<app-pricing></app-pricing>-->

<!-- <app-stats></app-stats>

<app-download></app-download> -->

<app-contact></app-contact>

<app-copyright></app-copyright>
