<!-- Header -->
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-5C3ZWYZK9Q"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-5C3ZWYZK9Q');
</script>
<header id="header" class="header">
  <div class="header-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="text-container">
            <h1>PROCESS & QUEUEING PLATFORM <br>FOR <span id="js-rotating">EFFICIENCY, REPORTING, SCALABILITY, PREDICTION</span></h1>
            <p class="p-large">JustQueue is one of the easiest and feature packed process & queueing platform in the market. Leave your details and be the first to know when the app is available for download or if you require a demo!</p>
            <a class="btn-solid-lg page-scroll header-button" href="#contact">CONTACT ME</a>
            <!-- <a class="btn-solid-lg page-scroll header-button" href="#your-link"><i class="fab fa-google-play"></i>PLAY STORE</a> -->
          </div>
        </div> <!-- end of col -->
        <div class="col-lg-6">
          <div class="image-container">
            <img class="img-fluid" src="assets/images/header-iphone.png" alt="alternative">
          </div> <!-- end of image-container -->
        </div> <!-- end of col -->
      </div> <!-- end of row -->
    </div> <!-- end of container -->
  </div> <!-- end of header-content -->
</header> <!-- end of header -->
<!-- end of header -->
