<div class="web-terms">
    <!-- Header -->
    <header class="ex-header bg-dark-blue">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 offset-xl-1">
                    <h1>Website Terms & conditions</h1>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </header> <!-- end of ex-header -->
    <!-- end of header -->

    <!-- Basic -->
    <div class="ex-basic-1 pt-6">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 offset-xl-1">
                    <h2 class="mb-4">1. TERMS OF WEBSITE USE</h2>
                    <p>1.1.	These Terms of Service (“Terms of Service”), including the documents referred to herein, makes provision for the terms and conditions applicable to all who use our site <a href="https://www.just-queue.co.za"><em>https://www.just-queue.co.za</em></a> (“our site”);</p>
                    <p class="mb-6">1.2.	Before making use of our site, please read these Terms of Service carefully. By accessing our site (which includes, visiting, registering or purchasing) you accept and agree to all the covenants and conditions imposed in this agreement;</p>
                    <p class="mb-6">1.3.	By accessing and using this site, you agree that you have read, understand and agree to these terms;</p>
                    <p class="mb-6">1.4.	We recommend that you print a copy of these Terms of Service for future reference;</p>
                    <p class="mb-6">1.5.	If you do not agree to these Terms of Service, we advise that your refrain from making use of our site.</p>

                    <h2 class="mb-4">2.	OTHER APPLICABLE TERMS</h2>
                    <p class="mb-6">2.1.	These Terms of Service refer to the following additional terms, which also apply to your use of our site; </p>
                    <p class="mb-6">2.2.	Our <a href="https://just-queue.co.za/web-privacy">Privacy Policy</a>, which sets out the terms on which we process any Personal Information;</p>

                    <h2 class="mb-4">3. ABOUT THE WEBSITE</h2>
                    <p class="mb-6">3.1.	Website: <a href="https://www.just-queue.co.za">https://www.just-queue.co.za</a> is an Internet website owned and operated by Just Corporation Proprietary Limited (“JC”, "we", “us”, “our”).</p>
                    <p class="mb-6">3.2.	The Just-Queue is an integrated platform that offers stakeholders in the Service Delivery Industry.</p>

                    <h2 class="mb-4">4. CHANGES TO THESE TERMS</h2>

                    <p class="mb-6">4.1.	The term(s) hereof may be amended any time. Such amendments will be applicable when published on our site.</p>
                    <p class="mb-6">4.2.	As they are binding on you, please check the Terms of Service from time to time to note any amendments. </p>

                    <h2 class="mb-4">5. CHANGES TO OUR SITE</h2>

                    <p class="mb-6">5.1.	We may update our site and Content from time to time.</p>
                    <p class="mb-6">5.2.	Unless otherwise agreed to under out terms of service, we may stop publishing on our site at any time. This may be done without notice and we will not be responsible for any consequences that result therefrom.</p>
                    
                    <h2 class="mb-4">6. ACCESSING OUR SITE</h2>

                    <p class="mb-6">6.1.	Access to our site is made available free of charge.</p>
                    <p class="mb-6">6.2.	Access to our services will be subject to certain charges which will be presented to you subsequent to registration as a user of the services.</p>
                    <p class="mb-6">6.3.	We do not guarantee that our site or any content on it, will always be available or be uninterrupted or error free. Access to our site is permitted on a temporary basis. We will not be liable to you, if for any reason, our site is unavailable;</p>
                    <p class="mb-6">6.4.	You are responsible for the arrangements necessary for you to have access to our site. This includes, but is not limited to, mobile data and the costs associated with it;</p>
                    <p class="mb-6">6.5.	We do not guarantee that our site will function on any particular hardware or devices. Use of our site may also be subject to malfunctions and delays inherent to the use of the Internet and electronic communications;</p>
                    <p class="mb-6">6.6.	You are responsible for ensuring that all persons who access our site through your Internet connection are aware of these Terms of Service, and that they comply accordingly.</p>

                    <h2 class="mb-4">7.	RIGHTS GRANTED TO YOU</h2>

                    <p class="mb-6">7.1.	Subject to these Terms of Service, we grant you a limited, non-exclusive, non-transferrable and revocable, license to access, and use our site and the Content at any time solely for your personal and non-commercial use. Use of any Products and/or Services will be subject to our Terms of Service.</p>
                    <p class="mb-6">7.2.	We reserve any rights not expressly granted herein. </p>

                    <h2 class="mb-4">8.	PROHIBITED USES</h2>

                    <p class="mb-6">You may not use our site or Content:</p>

                    <p class="mb-6">8.1.	in any way that breaches any applicable local, national or international law or regulation;</p>
                    <p class="mb-6">8.2.	in any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect; </p>
                    <p class="mb-6">8.3.	to transmit, or procure the sending of, unsolicited or unauthorised advertising, promotional material or any other form of similar solicitation (for example: Spam).</p> 
                    <p class="mb-6">8.4.	to knowingly transmit/send viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs.; or</p>
                    <p class="mb-6">8.5.	to gain unauthorised access, interfere with, damage or disrupt any part of our site and the equipment or network on which the website is stored.</p>
                    <p class="mb-6">8.6.	“Deep-linking”, “embedding” or using analogous technology is strictly prohibited.</p>

                    <h3>You may further not</h3>

                    <p class="mb-6">8.7.	make any speculative, false or fraudulent purchase(s);</p>
                    <p class="mb-6">8.8.	remove any copyright, trademark or other proprietary notices from our site or any Content;</p>
                    <p class="mb-6">8.9.	reproduce, copy, modify, adapt, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit our site or any part thereof or the Content;</p>
                    <p class="mb-6">8.10.	decompile, reverse engineer or disassemble our site or any Content;</p>
                    <p class="mb-6">8.11.	cause or launch any programs or scripts for the purpose of scraping, mirroring, indexing, surveying, or otherwise data mining any portion of our site; </p>
                    <p class="mb-6">8.12.	unduly burdening or hindering the operation and/or functionality of any aspect of our site;</p>
                    <p class="mb-6">8.13.	frame our site;</p>
                    <p class="mb-6">8.14.	access or use our website or the Content through automated means, including but not limited through the use of robots, spiders, or offline readers; or</p>
                    <p class="mb-6">8.15.	attempt to gain unauthorised access to or impair any aspect of our site and its related systems or networks.</p>
                    <p class="mb-6">8.16.	Incorporate e-mail addresses, names, telephone numbers and fax numbers published on our site into any database used for commercial purposes or electronic marketing or similar purposes. The presentation of such details is not an “opt-in” / permission from us to utilise the information.</p>
                    
                    <h2 class="mb-4">9.	INTELLECTUAL PROPERTY RIGHTS</h2>

                    <p class="mb-6">9.1.	All Intellectual Property available on the site is the property of or licensed to us and may not be used outside the licensed terms as set out under these Terms of Service;</p>
                    <p class="mb-6">9.2.	You may draw the attention of others to content posted on our site or by sharing it via social networks or other means available;</p>
                    <p class="mb-6">9.3.	For any re-use or distribution of the Content or Products and/or Services, as per the limited license, you must draw others’ attention to these Terms of Service;</p>
                    <p class="mb-6">9.4.	You must not modify the copies of any Content you have printed off or downloaded from our site. Any illustrations, photographs, video or audio sequences or graphics which accompany text, may not be used separately from the text nor may you claim that it is yours;</p>
                    <p class="mb-6">9.5.	We must always be acknowledged as the authors of the Content on our site;</p>
                    <p class="mb-6">9.6.	Unless otherwise agreed to under these Terms of Service, we do not grant you any rights:</p>
                    <p class="mb-6">9.6.1.	in or related to our site or the Products and/or Services (i.e. clause 7 above); or</p>
                    <p class="mb-6">9.6.2.	to use our business names, logos, trademarks or those of our licensors (registered or un-registered).</p>
                    <p class="mb-6">10.7.	We would be pleased to consider requests for permission to disseminate or use the Products and/or Services outside the scope of the granted licence. No permission is given unless it is provided in advance and in writing by us. To obtain approval, please send an e-mail to <a href="info@just-queue.co.za">info@just-queue.co.za</a>.</p>

                    <h2 class="mb-4">
                        10.	LIMITATION OF OUR LIABILITY
                    </h2>

                    <h4>IMPORTANT NOTICE:</h4>

                    <p class="mb-6">10.1.	We provide our site on an “AS-IS” and “AS-AVAILABLE” basis to the extent permitted by law and for registered member use only.  We EXCLUDE all conditions, warranties, representations or other terms which may apply to our site or any content on it, whether express or implied. We also make NO REPRESENTATION, GUARENTEE or WARRANTY regarding the timelines, quality, reliability, suitability, or availability of our site;</p>
                    <p class="mb-6">10.2.	Neither our site nor its Content Provider can be held responsible for any mistakes in printing or faulty instructions contained within the Products and/or Services;</p>
                    <p class="mb-6">10.3.	YOU AGREE that it is within your sole discretion to use our site and/or the Products and/or Services and that the entire RISK arising out of your use remains solely with you – to the MAXIMUM EXTENT permitted under any applicable law. YOU ALSO AGREE that you will INDEMNIFY us against any claim, demand, damage, costs, loss or liability (including reasonable attorney fees) related to your use of our site and/or the Products and/or Services;</p>
                    <p class="mb-6">10.4.	We, our owners, directors, employees, partners and agents shall NOT be LIABLE for any direct, indirect, incidental, special, or consequential DAMAGES caused by accessing or using our site or the Products and/or Services;</p>
                    <p class="mb-6">10.5.	You AGREE to INDEMNIFY and HOLD us, our directors, partners, employees and agents HARMLESS from all claims, demands, losses, liabilities and expenses (including attorney own client fees), arising out of or in connection with (I) YOUR BREACH or VIOLATION of any of these Terms of Service; and/or (II) YOUR VIOLATION of the RIGHTS of any THIRD PARTY, including our Third Party Providers;</p>
                    <p class="mb-6">10.6.	The LIMITATIONS and DISCLAIMER in this clause 11 DOES NOT LIMIT YOUR LIABILITY or ALTER your RIGHTS as a CONSUMER that cannot be excluded under any applicable law.</p>

                    <h2 class="mb-4">11.	SECURITY</h2>

                    <p class="mb-6">11.1.	We are under no obligation  to provide security on our site. Nevertheless, we feel it is important that your information, or any communication between us, is dealt with in the most secure manner reasonably possible. However, because of the nature of the Internet, we cannot guarantee that your communications with us via our site are completely secure at all times;</p>
                    <p class="mb-6">11.2.	To provide adequate security to all our Users, and to monitor activities prohibited under section 86 of the ECT Act, you agree to our right to intercept, monitor, block, read, delete or access all data sent to our website or any of our other communication facilities, for example, email, instant messaging or fax-to-email applications.  This is subject to the conditions as set out under the RIC Act;</p>
                    <p class="mb-6">11.3.	It is our policy to virus check documents and files before they are uploaded to our site. Despite this, we cannot guarantee that documents or files downloaded from our site will be free from viruses. We do not accept any responsibility for any damage or loss caused by any virus. For your own protection, you must use virus-checking software when using our site. You agree not to upload or provide, through our site, any document or file which may contain a virus. You must virus check any document or file which upload or provide to our or our site.</p>

                    <h2 class="mb-4">12.	LINKING TO OUR SITE</h2>

                    <p class="mb-6">12.1.	You may link to our site from your site or other social media page, but only to our Homepage. Such linking must be in a way that is fair, legal and does not damage our reputation, take advantage of it or breaches any provision of these Terms of Service;</p>
                    <p class="mb-6">12.2.	No link may be established in such a way that suggests any form of association, approval or endorsement on our part, where none exists;</p>
                    <p class="mb-6">12.3.	No link to our site may be provided in any website that is not owned by you;</p>
                    <p class="mb-6">12.4.	We reserve the right to withdraw linking permission without notice.</p>

                    <h2 class="mb-4">13.	HYPERLINKS FROM OUR SITE</h2>

                    <p class="mb-6">13.1.	Where our site or the Content, including Products and/or Services contain links to other websites, these links are provided for your convenience and information only. You acknowledge that different Terms of Service and privacy policies may apply to your use of such third party websites. We do not endorse or approve of the operators and/or owners of those third party websites, and material on those third party websites. In no event shall we be responsible or liable for any websites and websites such third party providers;</p>

                    <h2 class="mb-4">14.	SOCIAL MEDIA</h2>

                    <p class="mb-6">14.1.	Social networks: You agree that when accessing, using, posting or uploading any content or materials of any kind to our social network pages (including but not limited to Facebook, Twitter, LinkedIn, Instagram) or any other facility made available by us, you will – </p>
                    <p class="mb-6">14.1.1.	not use it in any improper or unlawful manner or in breach of any licence that applies to you;</p>
                    <p class="mb-6">14.1.2.	not harass others or disclose personal information about other;</p> 
                    <p class="mb-6">14.1.3.	not submit, publish, post, upload, store, distribute or disseminate any defamatory, infringing, offensive, obscene, indecent, harmful, confidential, hateful, threatening or otherwise illegal or objectionable material or information;</p>
                    <p class="mb-6">14.1.4.	not submit, post or upload files, without consent, that contain software or other material, the intellectual property rights of which are owned by any third party, or that is protected by rights of privacy or publicity of any third party;</p>
                    <p class="mb-6">14.1.5.	not upload files that contain viruses, corrupted files, or any other software or programs that may </p>
                    <p class="mb-6">14.1.6.	interfere with or damage the operation of the social network page or any other computer;</p> 
                    <p class="mb-6">14.1.7.	not impersonate any person or entity or falsely misrepresent yourself in any way;</p>
                    <p class="mb-6">14.1.8.	not promote any illegal activity;</p>
                    <p class="mb-6">14.1.9.	not use software to harvest information from the social media network page;</p>
                    <p class="mb-6">14.1.10.	not submit any material which is prohibited by any applicable data protection or privacy legislation;</p>
                    <p class="mb-6">14.1.11.	not submit, post or upload any content or materials or otherwise do anything in breach of the social media platform’s Terms of Service/Terms and Conditions;</p>
                    <p class="mb-6">14.1.12.	abide to the particular social media platform’s Code of Conduct or Community Guidelines or any other similar rules and guidelines.</p>
                    <p class="mb-6">14.1.13.	You agree that you will be solely responsible for all content, information or materials of whatever nature.</p>
                    <p class="mb-6">14.1.14.	or medium that you submit, post, upload, publish or display on or through the social media network.</p>
                    <p class="mb-6">14.1.15.	page or transmit to or share with other users ("User Content");</p>
                    <p class="mb-6">14.1.16.	You acknowledge and agree that, although we are not obligated, we may monitor the content on the social media platform page(s). This includes, but is not limited to, deleting from the said page without notice, any content of whatever nature, for any or no reason.</p>
                    <p class="mb-6">14.1.17.	Social media is not a medium for conflict resolution or lodging complaints. Complaints must be e-mailed to <a href="info@just-queue.co.za">info@just-queue.co.za</a>.</p>

                    <h2 class="mb-4">15.	FORMATION AND VALIDITY OF THIS AGREEMENT</h2>

                    <p class="mb-6">You agree that:</p>
                    <p class="mb-6">15.1.	these Terms of Service are valid, binding and enforceable and concluded in Pretoria, Gauteng, South Africa, the moment you access/view our site;</p>
                    <p class="mb-6">15.2.	if any term of this agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall not affect the legality, validity or enforceability in that jurisdiction of any other term of this agreement.</p>

                    <h2 class="mb-4">16.	BREACH, SUSPENSION AND TERMINATION</h2>

                    <p class="mb-6">16.1.	Kindly take note that it is within our discretion to determine whether, through your use, there has been a breach of these Terms of Service. When a breach occurs, we may take such action as we deem appropriate;</p>
                    <p class="mb-6">16.2.	We specifically exclude any liability for our actions taken in response to any breach of these Terms of Service;</p>
                    <p class="mb-6">16.3.	All costs, charges and expenses which may be incurred by us in enforcing our rights in terms hereof, shall be recoverable from you if the above rights are successfully enforced. These costs, charges and expenses include, without limitation, legal costs on the scale as between an attorney and own client and collection commission and is irrespective of whether any action has been instituted;</p>
                    <p class="mb-6">16.4.	No relaxation or indulgence, by either one of us, shall constitute a waiver of the rights of that party. It also shall not preclude that party from exercising any rights which may have arisen in the past or may arise in future;</p>
                    <p class="mb-6">16.5.	Any provision under these Terms of Service which contemplates performance or observance, after any termination or expiration of these Terms of Service, shall survive any termination or expiration and shall continue in full force and effect.</p>

                    <h2 class="mb-4">17.	APPLICABLE LAW AND JURISDICTION</h2>

                    <p class="mb-6">17.1.	These Terms of Service are governed by the laws of the Republic of South Africa. </p>
                    <p class="mb-6">17.2.	Subject to clause 19.3 below, any dispute arising in relation to these Terms shall, be referred to mediation (as per a mediator agreed to by both parties). If no mediator is agreed to, then the dispute shall be referred to arbitration in Pretoria at a venue of our choice. The Uniform Rules of the High Court of South Africa will apply;</p>
                    <p class="mb-6">17.3.	of the CPA (to the extent applicable) using the mechanisms provided Subject to clause 17.1 above, the parties further agree to the exclusive jurisdiction of the North Gauteng High Court, Pretoria, which shall have exclusive jurisdiction over any dispute that may arise from these Terms of Service.</p>
                    <p class="mb-6">17.4.	Kindly take note that no term, condition and/or provision of these Terms of Service is intended to limit any right to settle a dispute in terms for herein.</p>

                    <h2 class="mb-4">18.	ELECTRONIC COMMUNICATION AND CONTACT</h2>

                    <p class="mb-6">18.1.	Any Data Messages sent by us to you shall be deemed to have been sent from the Premises;</p>
                    <p class="mb-6">18.2.	A Data Message is deemed to be sent:</p>
                    <p class="mb-6">18.2.1.	By us, at the time shown on such message, or if not so shown, at the time shown on our information system; and</p>
                    <p class="mb-6">18.2.2.	By you, at the time when we confirm receipt (excluding an automated response) thereof;</p> 
                    <p class="mb-6">18.2.3.	A Data Message is deemed to be received:</p>
                    <p class="mb-6">18.2.3.1.	By us, only when an authorised representative responds thereto (excluding an automated response). Such acknowledgement does not give legal effect to that message, unless specifically indicated as such by us; and</p> 
                    <p class="mb-6">18.2.3.2.	By you, once it enters your information system;</p> 
                    <p class="mb-6">18.2.4.	As provided for in terms of section 11(3) of the ECT Act, all information incorporated by the use of hyperlinks and / or other methods of reference shall form part of these Terms of Service;</p>

                    <p class="mb-6">18.3.	Attribution of Data Messages – You agree and warrant that any Data Message sent from any computer or device that is owned by you or programmed by you to us was sent by you;</p>
                    <p class="mb-6">18.4.	Expression of Intent – use of our site: For purposes of electronic communications between you and us no electronic signature is required. The mere browsing of our site demonstrates your intent to be a party to this Terms of Service.</p>

                    <h2 class="mb-4">19.	CONTACT US</h2>

                    <p class="mb-6">19.1.	Website functionality or any other recommendations: Send us an email at <a href="info@just-queue.co.za">info@just-queue.co.za</a> or make use of our Suggestion/Feedback box;</p>
                    <p class="mb-6">19.2.	Questions or queries: Send us an email <a href="info@just-queue.co.za">info@just-queue.co.za</a> or make use of our Suggestion/Feedback box;</p>
                    <p class="mb-6">19.3.	Complaints: We kindly request that you contact us first should you have any complaints or any other related issues and queries. It is important to us that you are satisfied with the service we provide. Please send us an email at <a href="info@just-queue.co.za">info@just-queue.co.za</a>. We shall of course reply to your complaint as soon as practically possible, but we stand under no legal obligation to resolve such complaint;</p>
                    <p class="mb-6">19.4.	Legal Documentation or Notices:</p>
                    <p class="mb-6">19.4.1.	Physical address: our Premises;</p>
                    <p class="mb-6">19.4.2.	Email: <a href="info@just-queue.co.za">info@just-queue.co.za</a> (subject: “LEGAL”);</p>
                    <p class="mb-6">19.4.3.	Marked for the attention of: Managing Director;</p>

                    <p class="mb-6">19.5.	If we are required to send you any legal documents or notices you agree that we can send it via electronic mail to your email address, or by written communication by way of registered post to your address. If delivery to the aforesaid addresses is not successful, you agree that we can then use such contact details as we may find on the Internet about you;</p>
                    <p class="mb-6">19.6.	Any notice to you, or us, which is:</p>
                    <p class="mb-6">19.6.1.	sent by prepaid registered post in a correctly addressed envelope to the address specified for it under clause 20.4.1 above shall be deemed to have been received, unless the contrary is proven, within 10 (ten) days from the date on which it was posted;</p>
                    <p class="mb-6">19.6.2.	delivered by hand, shall be deemed to have been received on the day of delivery, provided that it has been delivered to a responsible person during ordinary business hours; or</p>
                    <p class="mb-6">19.6.3.	sent by a Data Message to the addressee shall be deemed to be received as per clause 18.2.3 above;</p>

                    <p class="mb-6">19.7.	Notwithstanding anything to the contrary herein, a written notice actually received by you or us, including a notice sent by telefax, shall be an adequate notice to it despite it not being sent or delivered to the chosen address.</p>

                    <h2 class="mb-4">20.	DEFINITIONS</h2>

                    <p class="mb-6">Unless context indicates otherwise, the following definitions will apply to the Terms of Service:</p>

                    <p class="mb-6">20.1.	<strong>Consumer</strong> shall have the same meaning attributed to it in terms of the CPA.</p>
                    <p class="mb-6">20.2.	<strong>Content</strong> means any information, content, images, video, audio, data, works of authorship, materials, software and technology which may be displayed on, incorporated into, underlying, or used to operate our site;</p>
                    <p class="mb-6">20.3.	<strong>CPA</strong> means the Consumer Protection Act, Act 68 of 2008;</p>
                    <p class="mb-6">20.4.	<strong>Products and/or Services</strong> means content that is specific to the products and/or services;</p>
                    <p class="mb-6">20.5.	<strong>Content Provider</strong> means Just Corporation Proprietary Limited and its licensors;</p>
                    <p class="mb-6">20.6.	<strong>Data Message</strong> shall have the same meaning attributed to it in terms of the ECT Act;</p>
                    <p class="mb-6">20.7.	<strong>ECT Act</strong> means the Electronic Communications and Transactions Act, Act 25 of 2002;</p>
                    <p class="mb-6">20.8.	<strong>Intellectual Property</strong> means but is not limited to, concepts, know-how, data processing techniques, copyrights, patents, designs (including our site’s look and feel), inventions, trademarks, tables and compilations of data which are created, invented and/or developed, registered or unregistered in our site and Content;</p>
                    <p class="mb-6">20.9.	<strong>Personal Information</strong> shall have the same meaning attributed to it in terms of the POPI Act;</p>
                    <p class="mb-6">20.10.	<strong>POPI Act</strong> means the Protection of Personal Information Act, Act 4 of 2013;</p>
                    <p class="mb-6">20.11.	<strong>RIC ACT</strong> means the Regulation of Interception of Communications and Provision of Communication Related Information Act, Act 70 of 2002;</p>
                    <p class="mb-6">20.12.	<strong>Users</strong> means those who access and use of our site.</p>

                    <h4>You can download a hard copy of this document below:</h4>
                    <a class="btn-solid-reg mb-6" href="https://just-queue-public-files.s3.af-south-1.amazonaws.com/Just-Queue-Web-Terms.docx">Download</a>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of ex-basic-1 -->
    <!-- end of basic -->
</div>
