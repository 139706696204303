import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { AppComponent } from './app.component';
import { AppLandingComponent } from './components/app-landing/app-landing.component';
import { AppRoutingModule } from './app-routing.module';
import { NavBarComponent } from './components/common/nav-bar/nav-bar.component';
import { PreLoaderComponent } from './components/common/pre-loader/pre-loader.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HeaderComponent } from './components/common/header/header.component';
import { TestimonialsComponent } from './components/common/testimonials/testimonials.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { VideoPreviewComponent } from './components/common/video-preview/video-preview.component';
import { DetailsComponent } from './components/common/details/details.component';
import { ScreenshotsComponent } from './components/common/screenshots/screenshots.component';
import { DownloadComponent } from './components/common/download/download.component';
import { StatsComponent } from './components/common/stats/stats.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { CopyrightComponent } from './components/common/copyright/copyright.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { ServicesComponent } from './components/common/services/services.component';
import { ProductsComponent } from './components/common/products/products.component';
import { ExpertiseComponent } from './components/common/expertise/expertise.component';
import { StoryComponent } from './components/common/story/story.component';
import { WebTermsComponent } from './components/web-terms/web-terms.component';
import { WebPrivacyComponent } from './components/web-privacy/web-privacy.component';
import { AppPrivacyComponent } from './components/app-privacy/app-privacy.component';
import { AppTermsComponent } from './components/app-terms/app-terms.component';

@NgModule({
  declarations: [
    AppComponent,
    AppLandingComponent,
    NavBarComponent,
    PreLoaderComponent,
    FooterComponent,
    HeaderComponent,
    TestimonialsComponent,
    FeaturesComponent,
    VideoPreviewComponent,
    DetailsComponent,
    ScreenshotsComponent,
    DownloadComponent,
    StatsComponent,
    ContactComponent,
    CopyrightComponent,
    PricingComponent,
    ServicesComponent,
    ProductsComponent,
    ExpertiseComponent,
    StoryComponent,
    WebTermsComponent,
    WebPrivacyComponent,
    AppPrivacyComponent,
    AppTermsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
