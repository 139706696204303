<!-- Video -->
<div id="preview" class="cards-2 cards-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2>HOW IT WORKS</h2>
      </div> <!-- end of col -->
    </div> <!-- end of row -->
    <div class="row" style="margin-top: 50px">
      <div class="col-lg-12">
        <div class="benefits-grid">
          <div class="card">
            <div class="card-image">
              <i class="fas fa-sign-in-alt"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">1. Sign Up</h4>
              <p>Sign Up and Register your business.</p>
            </div>
          </div>
          <div class="card">
            <div class="card-image">
              <i class="fas fa-th-list"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">2. Setup Processes</h4>
              <p>Setup your virtual processes on the platform.</p>
            </div>
          </div>
          <div class="card">
            <div class="card-image">
              <i class="fas fa-check-square"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">3. Choose Data Points</h4>
              <p>Choose the data points that you would like to measure.</p>
            </div>
          </div>
          <div class="card">
            <div class="card-image">
              <i class="fas fa-credit-card"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">4. Pay</h4>
              <p>Setup your payment details.</p>
            </div>
          </div>
          <div class="card">
            <div class="card-image">
              <i class="fas fa-user"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">5. Assign Operator</h4>
              <p>Add & assign an operator to your process points.</p>
            </div>
          </div>
          <div class="card">
            <div class="card-image">
              <i class="fas fa-mobile"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">6. Download App</h4>
              <p>Operator to download the Just Queue tool application.</p>
            </div>
          </div>
          <div class="card">
            <div class="card-image">
              <i class="fas fa-chart-pie"></i>
            </div>
            <div class="card-body">
              <h4 class="card-title">7. Start Monitoring</h4>
              <p>Start monitoring your processes & queues</p>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="row">-->
<!--      <div class="col-lg-12">-->

<!--        &lt;!&ndash; Video Preview &ndash;&gt;-->
<!--        <div class="image-container">-->
<!--          <div class="video-wrapper">-->
<!--            <a class="popup-youtube" href="https://www.youtube.com/watch?v=fLCjQJCekTs" data-effect="fadeIn">-->
<!--              <img class="img-fluid" src="assets/images/video-frame.jpg" alt="alternative">-->
<!--              <span class="video-play-button">-->
<!--                                    <span></span>-->
<!--                                </span>-->
<!--            </a>-->
<!--          </div> &lt;!&ndash; end of video-wrapper &ndash;&gt;-->
<!--        </div> &lt;!&ndash; end of image-container &ndash;&gt;-->
<!--        &lt;!&ndash; end of video preview &ndash;&gt;-->
<!--      </div> &lt;!&ndash; end of col &ndash;&gt;-->
<!--    </div> &lt;!&ndash; end of row &ndash;&gt;-->
  </div> <!-- end of container -->
</div> <!-- end of basic-1 -->
<!-- end of video -->
