<!-- Footer -->
<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="footer-col">
          <h4>Just Queue</h4>
          <p>The easiest and feature packed process & queueing platform in the market.</p>
        </div>
      </div> <!-- end of col -->
      <div class="col-md-4">
        <div class="footer-col middle">
          <h4>Important Links</h4>
          <ul class="list-unstyled li-space-lg">
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">Developed by <a class="turquoise" href="https://qltech.co.za">Quantum Leap Technologies (Pty) Ltd</a></div>
            </li>
            <li class="media">
              <i class="fas fa-square"></i>
              <div class="media-body">Read our <a class="turquoise" routerLink="web-terms">Terms & Conditions</a>, <a class="turquoise" routerLink="web-privacy">Privacy Policy</a></div>
            </li>
          </ul>
        </div>
      </div> <!-- end of col -->
      <div class="col-md-4">
        <div class="footer-col last">
          <h4>Social Media</h4>
          <!-- <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>
          <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-twitter fa-stack-1x"></i>
                            </a>
                        </span>
          <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-google-plus-g fa-stack-1x"></i>
                            </a>
                        </span> -->
          <span class="fa-stack">
                            <a href="https://www.instagram.com/just.queue/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-instagram fa-stack-1x"></i>
                            </a>
                        </span>
          <span class="fa-stack">
                            <a href="https://www.linkedin.com/company/just-queue/">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-linkedin-in fa-stack-1x"></i>
                            </a>
                        </span>
        </div>
      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of footer -->
<!-- end of footer -->
