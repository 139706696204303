import { AppPrivacyComponent } from './components/app-privacy/app-privacy.component';
import { AppTermsComponent } from './components/app-terms/app-terms.component';
import { WebPrivacyComponent } from './components/web-privacy/web-privacy.component';
import { WebTermsComponent } from './components/web-terms/web-terms.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLandingComponent } from './components/app-landing/app-landing.component';
import {PricingComponent} from './components/pricing/pricing.component';


const routes: Routes = [
  {path: '', component: AppLandingComponent},
  {path: 'pricing', component: PricingComponent},
  {path: 'web-terms', component: WebTermsComponent},
  {path: 'web-privacy', component: WebPrivacyComponent},
  {path: 'app-terms', component: AppTermsComponent},
  {path: 'app-privacy', component: AppPrivacyComponent}
];

@NgModule({
  declarations: [],
  imports: [
       RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
