<!-- Expertise -->
<div class="filter" id="story">
  <div class="container">
    <div class="row">
      <div class="col-lg-12"><!-- end of box-heading-wrapper -->
        <h2 class="h2-heading">Our Story</h2>
        <p class="p-heading p-large" style="text-align: justify; width: 90%; margin-top: 20px">
          Have you ever felt like the service you run can do better at serving your consumers? This is probably because you have felt this frustration of waiting in long
          queues not knowing when you will be served. Well, we have been in your shoes and decided to do something about it. At JustQueue, we understand that for
          you to give the level of transparancy that would keep customers coming back, you would need the insight to do so first. Answering questions such as, "How
          many customers arrive at any given hour?" or "Can the service handle the influx of people today?", is what will put your business miles ahead of your
          competitors. With JustQueue, you can get the most efficient process & queue management system for your business while increasing customer convenience
          and retention today.
        </p>
        <p class="p-heading p-large" style="text-align: justify; width: 90%;">
          The purpose of JustQueue is to be the scaffolding of your customer support that ensures smooth running when it comes to your service delivery. We analyse
          the entire process of your services, this includes the arrivals, queueing behaviour of both products & customers and the waiting area of your business, so
          that you may be empowered to make more informed decisions that best suit your business.
        </p>
        <p class="p-heading p-large" style="width: 90%;">
          JustQueue today.
        </p>
      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of cards-1 -->
<!-- end of expertise -->

