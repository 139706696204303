<!-- Contact -->
<div id="contact" class="form">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2>CONTACT</h2>
        <ul class="list-unstyled li-space-lg">
          <!-- <li class="address">Don't hesitate to give us a call or just use the contact form below</li> -->
          <li><i class="fas fa-map-marker-alt"></i>1 Mark Shuttleworth Street, Lynwood, Pretoria, 0087, RSA</li>
          <li><i class="fas fa-phone"></i><a class="blue" href="tel:+27817826517">+27 81 782 6517</a></li>
          <li><i class="fas fa-envelope"></i><a class="blue" href="mailto:info@just-queue.co.za">info@just-queue.co.za</a></li>
        </ul>
      </div> <!-- end of col -->
    </div> <!-- end of row -->
    <div class="row">
      <div class="col-lg-6 offset-lg-3">

        <!-- Contact Form -->
        <form id="contactForm" (ngSubmit)="sendEmail(formData)" data-toggle="validator" data-focus="false">
          <div class="form-group">
            <input type="text" class="form-control-input" id="cname" name="name" [(ngModel)]="formData.name" required>
            <label class="label-control" for="cname">Name</label>
            <div class="help-block with-errors"></div>
          </div>
          <div class="form-group">
            <input type="email" class="form-control-input" id="cemail" name="email" [(ngModel)]="formData.email" required>
            <label class="label-control" for="cemail">Email</label>
            <div class="help-block with-errors"></div>
          </div>
          <div class="form-group">
            <textarea class="form-control-textarea" id="cmessage" name="message" [(ngModel)]="formData.message" required></textarea>
            <label class="label-control" for="cmessage">Your message</label>
            <div class="help-block with-errors"></div>
          </div>
          <div class="form-group checkbox">
            <input type="checkbox" id="cterms" value="Agreed-to-Terms" required>I have read and agree to Just Queue's stated conditions in <a href="privacy-policy.html">Privacy Policy</a> and <a href="terms-conditions.html">Terms Conditions</a>
            <div class="help-block with-errors"></div>
          </div>
          <div class="form-group">
            <button type="submit" class="form-control-submit-button">SUBMIT MESSAGE</button>
          </div>
          <div class="form-message">
            <div id="cmsgSubmit" class="h3 text-center hidden"></div>
          </div>
        </form>
        <!-- end of contact form -->

      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of form -->
<!-- end of contact -->
