<!-- Cards -->
<div class="ex-cards-3" id="pricing">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="sm-wd-container">
          <h2 class="m-bottom-1">Pricing Options To Fit All Budget Sizes</h2>
          <p class="m-bottom-3">Short intro to prices.</p>
        </div> <!-- end of sm-wd-container -->
      </div> <!-- end of col -->
    </div> <!-- end of row -->

    <div class="row">
      <div class="col-lg-12">

        <!-- Card-->
        <div class="card">
          <div class="card-body">
            <div class="price"><span class="value">Free</span></div>
            <div class="package">STANDARD</div>
            <ul class="list-unstyled li-space-lg">
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Download Application</div>
              </li>
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">View Queues</div>
              </li>
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Upload personal data</div>
              </li>
            </ul>
          </div>
          <a class="btn-solid-reg page-scroll" href="#inquiry">INQUIRE</a>
        </div> <!-- end of card -->
        <!-- end of card -->

        <!-- Card-->
        <div class="card">
          <div class="card-body">
            <div class="price"><span class="currency">$</span><span class="value">99</span></div>
            <div class="package">ADVANCED</div>
            <ul class="list-unstyled li-space-lg">
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Feature 1</div>
              </li>
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Responsive design for devices</div>
              </li>
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Feature 3</div>
              </li>
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Feature 4</div>
              </li>
            </ul>
          </div>
          <a class="btn-solid-reg page-scroll" href="#inquiry">INQUIRE</a>
        </div> <!-- end of card -->
        <!-- end of card -->


        <!-- Card-->
        <div class="card">
          <div class="card-body">
            <div class="price"><span class="currency">$</span><span class="value">199</span></div>
            <div class="package">COMPLETE</div>
            <ul class="list-unstyled li-space-lg">
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Feature 1</div>
              </li>
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Feature 2</div>
              </li>
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Feature 3</div>
              </li>
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Feature 4</div>
              </li>
              <li class="media">
                <i class="fas fa-check"></i>
                <div class="media-body">Quality and prompt support</div>
              </li>
            </ul>
          </div>
          <a class="btn-solid-reg page-scroll" href="#inquiry">INQUIRE</a>
        </div> <!-- end of card -->
        <!-- end of card -->

      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of ex-cards-3 -->
<!-- end of cards -->
