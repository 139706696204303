<div class="pricing">
  <!-- Header -->
  <header class="ex-header-pricing">
    <div class="container">
      <div class="row">
        <div class="col-xl-10 offset-xl-1">
          <h1>Pricing options</h1>
        </div> <!-- end of col -->
      </div> <!-- end of row -->
    </div> <!-- end of container -->
  </header> <!-- end of ex-header -->
  <!-- end of header -->

  <div class="ex-cards-2 pt-6">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <!-- Card-->
          <div class="card">
            <div class="card-body">
              <div class="package">TIER 1</div>
              <div class="price"><span class="value">Free</span></div>
              <div class="period p-small">no costs</div>
              <p>Our first tier is completely free and includes the following:</p>
              <ul class="list-unstyled li-space-lg">
                <li><strong>UNLIMITED</strong> customer entries</li>
                <li><strong>2</strong> process points</li>
                <li><strong>STANDARD</strong> live dashboard</li>
                <li><strong>BASIC</strong> customer support</li>
                <li><strong>FREE SOFTWARE UPDATES</strong></li>
                <li><strong>CLOUD BASED COMPUTING</strong></li>
                <li><strong>1</strong> Process</li>
                <li><strong>6</strong> data points</li>
                <li><strong>1 DAY</strong> data storage</li>
              </ul>
              <div class="button-wrapper">
                <a class="btn-solid-reg page-scroll" href="https://portal.just-queue.co.za/authentication/signup">Get Started</a>
              </div>
            </div>
          </div> <!-- end of card -->
          <!-- end of card -->

        </div> <!-- end of col -->
        <div class="col-lg-3">

          <!-- Card-->
          <div class="card">
            <div class="card-body">
              <div class="package">TIER 2</div>
              <div class="price"><span class="value">Paid</span></div>
              <div class="period p-small">billed Monthly/Yearly</div>
              <p>This is our standard level paid product with added storage and includes the following:</p>
              <ul class="list-unstyled li-space-lg">
                <li><strong>UNLIMITED</strong> customer entries</li>
                <li><strong>2</strong> process points</li>
                <li><strong>STANDARD</strong> live dashboard</li>
                <li><strong>BASIC</strong> customer support</li>
                <li><strong>FREE SOFTWARE UPDATES</strong></li>
                <li><strong>CLOUD BASED COMPUTING</strong></li>
                <li><strong>1</strong> Process</li>
                <li><strong>6</strong> data points</li>
                <li><strong>30 DAYS</strong> data storage</li>
              </ul>
              <div class="button-wrapper">
                <a class="btn-solid-reg page-scroll" href="https://portal.just-queue.co.za/authentication/signup">Get Started</a>
              </div>
            </div>
          </div> <!-- end of card -->
          <!-- end of card -->

        </div> <!-- end of col -->
        <div class="col-lg-3">

          <!-- Card-->
          <div class="card">
            <div class="card-body">
              <div class="package">TIER 3</div>
              <div class="price"><span class="value">Paid</span></div>
              <div class="period p-small">billed Monthly/Yearly</div>
              <p>If you are looking for more flexibility and more process points, our third tier include the following:</p>
              <ul class="list-unstyled li-space-lg">
                <li><strong>UNLIMITED</strong> customer entries</li>
                <li><strong>UNLIMITED</strong> process points</li>
                <li><strong>STANDARD</strong> live dashboard</li>
                <li><strong>DEDICATED</strong> customer support</li>
                <li><strong>STANDARD</strong> analytics</li>
                <li><strong>CUSTOMER NOTIFICATIONS</strong></li>
                <li><strong>FREE SOFTWARE UPDATES</strong></li>
                <li><strong>CLOUD BASED COMPUTING</strong></li>
                <li><strong>UNLIMITED</strong> Processes</li>
                <li><strong>FULL SET</strong> data points</li>
                <li><strong>30 DAYS</strong> data storage</li>
              </ul>
              <div class="button-wrapper">
                <a class="btn-solid-reg page-scroll" href="https://portal.just-queue.co.za/authentication/signup">Get Started</a>
              </div>
            </div>
          </div> <!-- end of card -->
          <!-- end of card -->

        </div> <!-- end of col -->
        <div class="col-lg-3">

          <!-- Card-->
          <div class="card">
            <div class="card-body">
              <div class="package">TIER 4</div>
              <div class="price"><span class="value">Paid</span></div>
              <div class="period p-small">billed Monthly/Yearly</div>
              <p>If you are looking for a more bespoke solution, contact us for the fourth tier solution that includes the following:</p>
              <ul class="list-unstyled li-space-lg">
                <li><strong>UNLIMITED</strong> customer entries</li>
                <li><strong>UNLIMITED</strong> process points</li>
                <li><strong>BESPOKE</strong> live dashboard</li>
                <li><strong>DEDICATED</strong> customer support</li>
                <li><strong>BESPOKE</strong> analytics</li>
                <li><strong>CUSTOMER NOTIFICATIONS</strong></li>
                <li><strong>FREE SOFTWARE UPDATES</strong></li>
                <li><strong>CLOUD BASED COMPUTING</strong></li>
                <li><strong>UNLIMITED</strong> Processes</li>
                <li><strong>BESPOKE SOLUTION</strong></li>
                <li><strong>FULL SET</strong> data points</li>
                <li><strong>UNLIMITED</strong> data storage</li>
              </ul>
              <div class="button-wrapper">
                <a class="btn-solid-reg page-scroll" href="https://portal.just-queue.co.za/authentication/signup">Get Started</a>
              </div>
            </div>
          </div> <!-- end of card -->
          <!-- end of card -->

        </div> <!-- end of col -->
      </div> <!-- end of row -->

      <div class="row" style="justify-content: center;">
        <h3>Process Point Pricing</h3>
        <table class="table table-striped table-bordered" style="margin-top: 20px;">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Tier 1</th>
              <th scope="col">Tier 2</th>
              <th scope="col">Tier 3</th>
              <th scope="col">Tier 4</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1-2</th>
              <td>Free</td>
              <td>R500/M</td>
              <td>-</td>
              <td>Bespoke</td>
            </tr>
            <tr>
              <th scope="row">3-20</th>
              <td>-</td>
              <td>-</td>
              <td>R100/PP/M</td>
              <td>Bespoke</td>
            </tr>
            <tr>
              <th scope="row">21-50</th>
              <td>-</td>
              <td>-</td>
              <td>R95/PP/M</td>
              <td>Bespoke</td>
            </tr>
            <tr>
              <th scope="row">51-100</th>
              <td>-</td>
              <td>-</td>
              <td>R90/PP/M</td>
              <td>Bespoke</td>
            </tr>
            <tr>
              <th scope="row">101-200</th>
              <td>-</td>
              <td>-</td>
              <td>R85/PP/M</td>
              <td>Bespoke</td>
            </tr>
            <tr>
              <th scope="row">201-500</th>
              <td>-</td>
              <td>-</td>
              <td>R80/PP/M</td>
              <td>Bespoke</td>
            </tr>
            <tr>
              <th scope="row">501-1000</th>
              <td>-</td>
              <td>-</td>
              <td>R75/PP/M</td>
              <td>Bespoke</td>
            </tr>
            <tr>
              <th scope="row">1001+</th>
              <td>-</td>
              <td>-</td>
              <td>R70/PP/M</td>
              <td>Bespoke</td>
            </tr>
          </tbody>
        </table>
        <p> Unlimited Historical Data Storage Fee - R500.00/month</p>
      </div>
    </div> <!-- end of container -->
  </div>

</div>
