<!-- Statistics -->
<div class="counter">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">

        <!-- Counter -->
        <div id="counter">
          <div class="cell">
            <div class="counter-value number-count" data-count="231">1</div>
            <p class="counter-info">Happy Users</p>
          </div>
          <div class="cell">
            <div class="counter-value number-count" data-count="85">1</div>
            <p class="counter-info">Issues Solved</p>
          </div>
          <div class="cell">
            <div class="counter-value number-count" data-count="59">1</div>
            <p class="counter-info">Good Reviews</p>
          </div>
          <div class="cell">
            <div class="counter-value number-count" data-count="127">1</div>
            <p class="counter-info">Case Studies</p>
          </div>
        </div>
        <!-- end of counter -->

      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of counter -->
<!-- end of statistics -->
