<!-- Details 1 -->
<div id="details" class="basic-2">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <img class="img-fluid" src="assets/images/details-1-iphone.png" alt="alternative">
      </div> <!-- end of col -->
      <div class="col-lg-6">
        <div class="text-container">
          <h3>Goals Setting</h3>
          <p>Leno can easily help you track your personal development evolution if you take the time to properly setup your goals at the beginning of the training process. Check out the details</p>
          <a class="btn-solid-reg popup-with-move-anim" href="#details-lightbox-1">LIGHTBOX</a>
        </div> <!-- end of text-container -->
      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of basic-2 -->
<!-- end of details 1 -->


<!-- Details 2 -->
<div class="basic-3">
  <div class="second">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="text-container">
            <h3>Calendar Input</h3>
            <p>The calendar input function enables the user to setup training, meditation and relaxation sessions with ease. Just open the feature and start setting up your time as you desire</p>
            <a class="btn-solid-reg popup-with-move-anim" href="#details-lightbox-2">LIGHTBOX</a>
          </div> <!-- end of text-container -->
        </div> <!-- end of col -->
        <div class="col-lg-6">
          <img class="img-fluid" src="assets/images/details-2-iphone.png" alt="alternative">
        </div> <!-- end of col -->
      </div> <!-- end of row -->
    </div> <!-- end of container -->
  </div> <!-- end of second -->
</div> <!-- end of basic-3 -->
<!-- end of details 2 -->
