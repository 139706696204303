<!-- Download -->
<div class="basic-4">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-xl-5">
        <div class="text-container">
          <h2>Download <span class="blue">Just Queue</span></h2>
          <p class="p-large">No more guessing how long the queue will take. Download the app now.</p>
          <a class="btn-solid-lg header-button" href="#your-link"><i class="fab fa-apple"></i>APP STORE</a>
          <a class="btn-solid-lg header-button" href="#your-link"><i class="fab fa-google-play"></i>PLAY STORE</a>
        </div> <!-- end of text-container -->
      </div> <!-- end of col -->
      <div class="col-lg-6 col-xl-7">
        <div class="image-container">
          <img class="img-fluid" src="assets/images/download.png" alt="alternative">
        </div> <!-- end of img-container -->
      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of basic-4 -->
<!-- end of download -->
