<!-- Projects -->
<div class="filter" id="industries">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="h2-heading">Industries</h2>
      </div> <!-- end of col -->
    </div> <!-- end of row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="grid">
          <div class="element-item">
            <a class="popup-with-move-anim" href="#project-1"><img class="img-fluid" src="assets/images/banking-icon.png" alt="alternative"></a>
            <p><strong>Banking</strong> -  A client walks into the bank around lunch time with just under 30 minutes to spare. There are 7 clients in front of her and only 3 of the teller stalls are occupied...</p>
          </div>
          <div class="element-item">
            <a class="popup-with-move-anim" href="#project-2"><img class="img-fluid" src="assets/images/manufacturing-icon.png" alt="alternative"></a>
            <p><strong>Manufacturing</strong> - Queues don’t just apply to people physically waiting to receive service but as a manufacturer you can find out your daily productivity...</p>
          </div>
          <div class="element-item">
            <a class="popup-with-move-anim" href="#project-3"><img class="img-fluid" src="assets/images/healthcare-icon.png" alt="alternative"></a>
            <p><strong>Healthcare</strong> - Healthcare being the most critical services to customers means the wait can be very dangerous...</p>
          </div>
          <div class="element-item">
            <a class="popup-with-move-anim" href="#project-4"><img class="img-fluid" src="assets/images/retail-icon.png" alt="alternative"></a>
            <p><strong>Retail</strong> - In the retail industry, customers often encounter long queues and find it difficult to estimate how long it will take to receive assistance....</p>
          </div>
          <div class="element-item">
            <a class="popup-with-move-anim" href="#project-5"><img class="img-fluid" src="assets/images/beauty-icon.png" alt="alternative"></a>
            <p><strong>Beauty</strong> - Everyone knows the agitation of waiting behind a picky client at a beauty salon or at the barber...</p>
          </div>
          <div class="element-item">
            <a class="popup-with-move-anim" href="#project-6"><img class="img-fluid" src="assets/images/motoring-icon.png" alt="alternative"></a>
            <p><strong>Motoring Services</strong> - As a carwash/ car service centre on a busy day, you cannot afford to delay any part of the process...</p>
          </div>
          <div class="element-item">
            <a class="popup-with-move-anim" href="#project-7"><img class="img-fluid" src="assets/images/travel-icon.png" alt="alternative"></a>
            <p><strong>Travel</strong> - Have you noticed that the airlines with bad reviews are not necessarily those with “bad facilities” but rather...</p>
          </div>
          <div class="element-item">
            <a class="popup-with-move-anim" href="#project-8"><img class="img-fluid" src="assets/images/food-icon.png" alt="alternative"></a>
            <p><strong>Food</strong> - The restaurant business is one with two queues that require close monitoring, order and customer queues...</p>
          </div>
        </div> <!-- end of grid -->
        <!-- end of filter -->

      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of filter -->
<!-- end of projects -->

<!-- Project Lightboxes -->
<!-- Lightbox -->
<div id="project-1" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/banking-icon.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Banking</h3>
        <p>A client walks into the bank around lunch time with just under 30 minutes to spare. There are 7 clients in front of her and only 3 of the teller available. What then?</p>
        <p>JustQueue can redistribute such clients to branches with lesser queues. Quicker service, happy client with JustQueue.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->
<!-- end of lightbox -->

<!-- Lightbox -->
<div id="project-2" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/manufacturing-icon.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Manufacturing</h3>
        <p>Queues don’t just apply to people physically waiting to receive service but as a manufacturer you can find out your daily productivity. Analysing how stoppages in production could affect your bottom line.</p>
        <p>JustQueue can help you predict and visualise production statistics.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->
<!-- end of lightbox -->

<!-- Lightbox -->
<div id="project-3" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/healthcare-icon.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Healthcare</h3>
        <p>Healthcare being the most critical services to customers means the wait can be very dangerous. An inbound patient approaching your healthcare facility may want to know that you can help them in advance. This could even be an ambulance bringing a patient in critical condition.</p>
        <p>JustQueue can help you predict the average waiting period and the efficiency of your queues.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->
<!-- end of lightbox -->

<!-- Lightbox -->
<div id="project-4" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/retail-icon.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Retail</h3>
        <p>In the retail industry, customers often encounter long queues and find it difficult to estimate how long it will take to receive assistance. This uncertainty may result in customers leaving the store. However, what if this issue could be resolved?</p>
        <p>JustQueue can help you and your customers gain a better understanding on the average waiting time. This means that a queue which appears as though it might take 15 minutes to get assistance could actually only require a 5-minute wait.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->
<!-- end of lightbox -->

<!-- Lightbox -->
<div id="project-5" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/beauty-icon.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Beauty</h3>
        <p>Everyone knows the agitation of waiting behind a picky client at a beauty salon or at the barber. An hour or two on a seat without service is enough to drive even the most loyal clients away, but with JustQueue you can eliminate that.</p>
        <p>JustQueue helps notify clients on their position in the queue throughout the day. This means they have the luxury of waiting anywhere. Thus, leaving your employees free to focus on present clients without agitated clients standing in line.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->
<!-- end of lightbox -->

<!-- Lightbox -->
<div id="project-6" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/motoring-icon.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Motoring Services</h3>
        <p>As a carwash/ car service centre on a busy day, you cannot afford to delay any part of the process as this threatens customers leaving before partaking in the service. Similarly, you cannot rush the process as the quality of the job will be at risk.</p>
        <p>With JustQueue, you will receive data reflecting your service quality against the time on each process. This ensures customer satisfaction and efficient queues.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->
<!-- end of lightbox -->

<!-- Lightbox -->
<div id="project-7" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/travel-icon.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Travel</h3>
        <p> Have you noticed that the airlines with bad reviews are not necessarily those with “bad facilities”, but rather those with long waiting check-in queues?</p>
        <p>JustQueue will help you manage that waiting period by minimising queue time according to your flight schedule. We provide data that is personalised to prioritize your customers making their flight on time.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->
<!-- end of lightbox -->

<!-- Lightbox -->
<div id="project-8" class="lightbox-basic zoom-anim-dialog mfp-hide">
  <div class="row">
    <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
    <div class="col-lg-6">
      <img class="img-fluid" src="assets/images/food-icon.png" alt="alternative">
    </div> <!-- end of col -->
    <div class="col-lg-6">
      <div class="text-container">
        <h3>Food</h3>
        <p>The restaurant business is one with two queues that require close monitoring, order and customer queues. Most has either witnessed or heard a customer yell these words, “Call the manager!”. JustQueue helps you to monitor both queues and provide the best system for your business.</p>
        <p>JustQueue will help give your employees and customers the most pleasant experience at your establishment.</p>
      </div> <!-- end of text-container -->
    </div> <!-- end of col -->
  </div> <!-- end of row -->
</div> <!-- end of lightbox-basic -->
<!-- end of lightbox -->
<!-- end of project lightboxes -->
